/* CookieBanner.css */
.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #272626;
    color: #fff;
    padding: 15px;
    text-align: center;
    z-index: 1000;
}

.cookie-banner p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
}

.cookie-banner a {
    color: #4caf50;
    text-decoration: underline;
}

.cookie-banner button {
    margin-left: 15px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cookie-banner button:hover {
    background-color: #45a049;
}