/* Główna karta produktu */
.product-card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #fff;

    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-top: 16px;
    padding-bottom: 16px;
    height: 100%;
}

/* Kategoria produktu (stała wysokość) */
.product-card-category {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
    text-transform: uppercase;
    text-align: center;
    min-height: 3rem;
    padding-left: 16px;
    padding-right: 16px;
    /* Wymuszenie stałej wysokości */
}

/* Zdjęcie produktu */
.product-card-image-container {
    position: relative;
    width: 100%;
    padding-top: 100%;
    /* Kwadratowy kontener */
    overflow: hidden;
    background-color: #f5f5f5;
    margin-bottom: 16px;
}

.product-card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Nazwa produktu (stała wysokość) */
.product-card-name {
    font-family: var(--default-heading-font-family);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 16px;
    min-height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    /* Wymuszenie stałej wysokości */
    display: flex;
    align-items: center;
    justify-content: center;
    /* Wycentrowanie tekstu */
}


.product-card-name a {

    text-decoration: none;
    color: #333;
}

.product-card-name a:hover {
    text-decoration: none;
    color: #7e8086;
}

.product-card-name h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

/* Sekcja stopki (cena i przycisk na dole) */
.product-card-footer {
    margin-top: auto;
    /* Wypycha stopkę na dół */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}

.product-card-edit-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
}

.product-card-edit-options .edit-option {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px
        /* Zmniejszono odległość między divami */
}

.product-card-edit-options .edit-option span {
    flex: 1;
    text-align: right;
    /* Dodano odstęp między label a przyciskami */
}

.product-card-edit-options .edit-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin: 5px;
}

.product-card-edit-options button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 0 2px;
    /* Zmniejszono odległość między przyciskami */
    cursor: pointer;
    border-radius: 5px;
    /* Zaokrąglenie przycisków */
    transition: background-color 0.3s;
    /* Dodano efekt przejścia */

    .product-card-edit-options button:hover {
        background-color: #0056b3;
    }
}

.product-card-edit-options button:hover {
    background-color: #0056b3;
    /* Efekt po najechaniu */
}

.product-card-edit-options .edit-buttons span {
    margin: auto;
    /* Zmniejszono odległość między przyciskiem a tekstem */
    text-align: center;

}

.product-card-price {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}


.product-card-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px;
    text-decoration: none;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.product-card-allegro {
    background-color: rgb(230, 98, 10);
}

.product-card-allegro:hover {
    background-color: rgb(90, 81, 75);
}