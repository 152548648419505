:root {
  --default-background-color: #e9ecef;
  --default-font: "Open Sans", sans-serif;
  --default-font: "Lato", sans-serif;
  --default-font: "lato-bold", sans-serif;
  --default-heading-font-family: "Open Sans", sans-serif;
  --font-menu-item: "Open Sans", sans-serif;
  --font-shop-header: "Open Sans", sans-serif;
  --default-text-color: "red";
  --menu-item-2-background-color: #14213d;
  --menu-item-2-color: #e5e5e5;
}

html {
  font-size: 14px;
}

body {
  font-family: var(--default-font);
  font-weight: normal;
  /* background-color: red; */
  color: var(--text-color);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@media screen and (max-width: 960px) {
  .card {
    padding: 0;
  }
}