div.shop_header {
    font-family: var(--font-shop-header);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}

@media (max-width: 1170px) {
    .shop_header {
        padding: 10px;
        /* Ustaw padding dla mniejszych rozdzielczości */
    }
}

div.shop_header a {
    font-size: medium;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
}

div.shop_header a:hover {
    color: #0949d4;
    cursor: pointer;
    ;
}

.shopAddButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: #828a83;
    color: white;
    border: none;
    border-radius: 10%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px;
    /* Płynna animacja */

}

.shopAddButton:hover {
    background-color: #45a049;
    /* Kolor po najechaniu */
}

.shopHeaderNavigation {

    text-align: left;
    flex: 1;
    /* Wypełnia dostępną przestrzeń */
}