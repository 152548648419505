.product-tiles-section {
    margin: 0;
    /* Odstęp wokół sekcji */

}

.product-tiles-header {
    font-size: 1.5rem;
    /* Rozmiar czcionki nagłówka */
    font-weight: bold;
    /* Pogrubienie tekstu */
    margin-bottom: 15px;
    /* Odstęp poniżej nagłówka */
    text-align: left;
    /* Wyśrodkowanie nagłówka */

    /* Kolor tekstu */
}

.product-tiles {
    margin-top: auto;
    /* Przesuwa element na dół kontenera */
    width: 100%;
    display: flex;
    /* Ustawia elementy w linii */
    flex-wrap: wrap;
    /* Pozwala elementom zawijać się, gdy brakuje miejsca */
    gap: 10px;
    /* Odstęp między kafelkami */
    justify-content: flex-start;
    /* Wyrównanie do lewej */
    align-items: flex-start;
    /* Wyrównanie do góry */
}