/* Ogólny styl stopki */
.footer {
    display: flex;
    flex-wrap: column;
    /* Zapewnia, że elementy mogą się zawijać w nową linię */
    background-color: #333;
    color: #fff;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* Opcjonalne, jeśli chcesz wyrównać tekst wewnątrz */
    /* margin: 0 auto; */
}

div.footer-container {
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    /* Zapewnia, że elementy mogą się zawijać w nową linię */
    justify-content: space-between;
    padding: 10px;

    width: 100%;



}

/* Styl dla sekcji kontaktowej */
.footer-contact {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

p.footer-contact-item {
    margin: 5px 0;

    text-align: left;
}



.footer-contact-item i {
    margin-right: 8px;
    text-align: left;

}

.footer-social-links {
    margin-top: 10px;
    display: flex;
}

.footer-social-icon {
    margin: 0;
    margin-right: 20px;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-social-icon:hover {
    color: #4caf50;
}

/* Copyright - początkowe pozycjonowanie */
.footer-copyright {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;
}

/* Stylizacja linków mailto, telefonicznych itp. */
.footer-contact a {
    color: white;
    text-decoration: none;
}

.footer-contact a:hover {
    color: #4caf50;
}

/* Media query dla wąskich ekranów (np. telefony) */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        /* Zmienia układ na kolumnowy */
        align-items: center;
        /* Centruje zawartość */
    }

    .footer-contact {
        align-items: center;
        /* Centruje sekcję kontaktową */
        margin-bottom: 10px;
        /* Odstęp między sekcjami */
    }

    .footer-copyright {
        justify-content: center;
        /* Centruje copyright */
        margin-top: 10px;
    }
}