div.shop {
    max-width: 1300px;
    padding: 20px;
    padding-top: 0px;
    margin: 0 auto;

}

div.shop-main {
    display: flex;
    padding: auto;

}

div.shop-menu {
    padding: 0.5rem;
}