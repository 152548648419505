.product-details-section {
    padding: 20px 5px 0px 5px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.product-details-tabs {
    display: flex;
    width: 100%;
    gap: 10px;
    margin-left: 10px;
    margin-bottom: 0;
    position: relative;
}

.product-details-tab {
    padding: 10px 20px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid var(--menu-item-2-background-color);
    background-color: var(--menu-item-2-background-color);
    color: var(--menu-item-2-color);
    font-weight: 600;
    border-radius: 5px 5px 0 0;
    transition: background-color 0.3s;
    position: relative;
    z-index: 1;
}

.product-details-tab.active {
    color: var(--menu-item-2-background-color);

    border-bottom: 1px solid var(--default-background-color);

    background-color: transparent;
    /* Usuń dolną granicę aktywnego przycisku */
}

/* .product-details-tab:hover {
    background-color: #e0e0e0;
}
 */

.product-details-content-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid var(--menu-item-2-background-color);
    margin-top: -1px;
    /* Usuń górną granicę, aby połączyć z aktywnym przyciskiem */


}

.product-details-content {
    display: block;

}

/* Media query dla małych rozdzielczości */
@media (max-width: 768px) {
    .product-details-tabs {
        flex-direction: column;
        margin-left: 0;
    }

    .product-details-tab {
        border-radius: 5px;
        background-color: #828a83;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border: none
            /* Dodaj cień do przycisków */
    }

    .product-details-tab.active {
        border: none;
        background-color: var(--menu-item-2-background-color);
        color: var(--menu-item-2-color);
    }

    .product-details-content-container {
        border-top: none;
        margin-top: 0;
    }
}