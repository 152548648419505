/* Karta podobnego produktu */
.similar-product-card {
    display: flex;
    width: 100%;
    flex-direction: column;
    /* Układ pionowy */
    align-items: stretch;
    /* Rozciągnięcie elementów */
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 8px;
    text-align: center;
    transition: transform 0.3s ease;
    height: 100%;

}

.similar-product-card:hover {
    transform: translateY(-5px);
    /* Efekt podniesienia na hover */
}

/* Kontener zdjęcia */
.similar-product-image-container {
    position: relative;
    width: 100%;
    padding-top: 100%;
    /* Kwadratowy kontener */
    overflow: hidden;
    background-color: #f5f5f5;
    margin-bottom: 8px;
}

/* Zdjęcie */
.similar-product-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Przycięcie zdjęcia */
}

/* Nazwa produktu */
.similar-product-name {
    /* Wymuszenie jednakowej wysokości dla nazw */
    font-family: var(--default-heading-font-family);
    display: flex;
    align-items: center;
    justify-content: center;
    /* Wycentrowanie tekstu */
}

.similar-product-name a {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    min-height: 40px;
    text-decoration: none;
    align-items: center;
    justify-content: center;

}