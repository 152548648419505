div.product_current_images {
    text-align: center;
    margin: auto;
    padding: auto;
}

div.product_current_image {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    padding: 20px;

}

/* div.product_current_image_image {
    display: flex;

} */

div.product_current_image_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}

div.product_current_image_image img {
    max-width: 300px;
    height: auto;
}