div.log_in {
    min-height: 100vh;
    max-width: 500px;
    padding: 20px;
    padding-top: 40px;
    margin: 0 auto;
    /* background-color: red; */
}

.alert {
    padding: 20px;
    background-color: #f44336;
    /* Red */
    color: white;
    margin-bottom: 15px;
    border-radius: 20px;
}

/* The close button */
.closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
    color: black;
}