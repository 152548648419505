/* Header */
.header {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    justify-content: center;
    transition: all 0.5s ease;
}

.header-container {
    max-width: 1300px;
    width: 100%;
    transition: all 0.5s ease;
    margin: auto;
    /* Wyśrodkuj kontener */
}

/* Logo */
.logo-container {
    /* flex-shrink: 0; */
    height: 100%;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    /* Ukrywa część obrazu wykraczającą poza div */
    display: flex;
    /* Ustawienie Flexboxa do lepszego układu */
    justify-content: center;
    /* Wyśrodkowanie poziome */
    align-items: center;
    /* Wyśrodkowanie pionowe */
    transition: all 0.5s ease;


}

.logo-container img {
    max-width: 100%;
    /* Ogranicza szerokość obrazu do kontenera */
    max-height: 100%;
    /* Ogranicza wysokość obrazu do kontenera */
    object-fit: contain;
    /* Skalowanie obrazu, aby zachować proporcje */
    cursor: pointer;
}


/* Header content */
.header-content {
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0;


}

/* Menu */
.header-content-menu {
    margin: 0;
    padding: 0;
    align-items: center;
    flex: 0 0 auto;
    justify-content: center;
    /* Wyśrodkowanie poziome */
    align-items: center;
    margin: auto;
    /* Wyrównuje elementy pionowo do środka */

}

.header-content-info {
    display: flex;
    justify-content: space-between;
    /* Rozstawia elementy po przeciwnych stronach */
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: auto;
    padding: 0;
    padding-right: 10px;

    /* Cała szerokość kontenera */
}


.menu-item {
    /* margin-right: 10px; */
    text-decoration: none;
    color: rgb(68, 66, 66);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    font-feature-settings: var(--font-feature-settings, normal);
    font-family: var(--font-manu-item);
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    display: flex;
    /* Ustawia Flexbox na kontenerze */
    align-items: center;
    flex: 0 0 auto;

}

/* Efekt hover: zmiana koloru tła i cień */
.menu-item:hover {
    background-color: #afb0b1;
    transform: scale(1.05);
}

/* Efekt kliknięcia: cień */
.menu-item:active {
    transform: scale(1);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);

}

/* Podstawowe style */
body {
    font-family: var(--default-font);
    margin: 0;
    padding: 0;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 20px;
    height: 40px;
    padding-top: 0px;
}

/* Menu */
.menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu li {}

.close-menu {
    display: none;
    margin: 10px;
}

/* Ikona hamburgera */
.hamburger {
    display: none;
    /* Domyślnie ukryta */
    color: rgb(68, 66, 66);
    flex-direction: column;
    justify-content: space-between;
    width: 40px;
    height: 100%;
    cursor: pointer;
    padding: 10px;
}

.hamburger span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: rgb(68, 66, 66);
    border-radius: 2px;
    color: rgb(68, 66, 66);
}

.close-icon {
    font-size: 30px;
    color: white;
    font-weight: bold;
}

/* Styl na małych rozdzielczościach */
@media (max-width: 1000px) {
    .menu {
        display: flex;
        /* Ustawiamy menu w flex */
        flex-direction: column;
        /* Ustawienie menu w kolumnie */
        background-color: white;
        position: absolute;
        top: 0;
        /* Zaczyna od góry */
        left: 0;
        width: auto;
        /* padding: 10px; */
        padding-top: 0;
        /* Menu na całą szerokość */
        transform: translateY(-120%);
        /* Menu początkowo jest przesunięte do góry */
        transition: transform 0.3s ease-in-out;
        /* Płynne przesunięcie */
        z-index: 100;
        overflow: hidden;
        /* Zapewni, że menu będzie nad innymi elementami */

        border-radius: 15px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    .menu.active {
        transform: translateY(0);
        /* Menu przesuwa się na swoje miejsce */


    }

    .menu li {
        /* margin: 10px; */
        margin: 10px;
        margin-top: 0px;
        text-align: center;
    }

    .hamburger {
        display: flex;
        /* Pokazuje hamburgera na małych ekranach */
    }

    .close-menu {
        display: flex;
        /* Domyślnie ukryta */
        /* flex-direction: column;
        justify-content: space-between; */
        width: 40px;
        height: 40px;
        cursor: pointer;
        justify-content: center;
        /* Wyśrodkowanie poziome */
        align-items: center;
        margin-top: 0;
    }
}