div.card {}

div.shop-list {
    width: 100%;

    padding-left: 35px;
    /* padding-right: 35px; */
}

div.shop-settings-top {
    /* padding-bottom: 0.5rem; */
    font-weight: 700;
    cursor: pointer;
    border: none;
    /* margin-bottom: 2px; */
    display: flex;
    flex-wrap: wrap;

}

div.shop-settings-top-right {
    display: flex;
    justify-content: end;
    padding: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    margin: 0;
}




div.shop-settings-top span {
    height: 3rem;
    align-items: center;
}