/* Główna struktura slidera */
.slider-container {
    max-width: 1300px;
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
}

/* Przycisk nawigacyjny */
.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.slider-button.prev {
    left: 10px;
}

.slider-button.next {
    right: 10px;
}

.slider-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Wrapper na całą zawartość */
.slider-wrapper {
    width: 100%;
    overflow: hidden;
}

/* Kontent slidera */
.slider-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

/* Pojedynczy element slidera */
.slider-item {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;

}