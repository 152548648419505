div.product_info {
    /* margin-left: 0;
    margin-right: 0; */

}

div.galery-container {
    padding: 0.5rem
}

div.galery {
    width: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    overflow: hidden
        /* padding: 0; */

}

div.product-description {
    padding: 1rem;
}

div.product-main-description {
    padding: 0.5rem;

    display: flex;
    flex-direction: column;
    /* Ustawiamy Flexbox na rodzicu */

}


div.product-notes {
    padding: 1rem;
}

div.product-main-description h1 {
    margin-top: 0rem;
    font-size: xx-large;
    margin-bottom: 0.5rem;
    font-family: var(--default-heading-font-family);
}

div.product-main-description p {
    font-size: 2rem;
    /* font-weight: bold; */
    margin-top: 1rem;
    font-size: x-large;
    font-style: italic;
    /* Dodaj kursywę */

}


div.delivery_note {}

img.main-image {
    max-height: 450px;
    max-width: 100%;
    width: 100%;
}

/* 
div.p-galleria-item {
    width: 100%;
    height: 400px;

} */

.p-galleria .p-galleria-item-nav {
    margin: 0;
    width: 2rem;
    height: 6rem;
    border-radius: 0;
    color: white;
    background: #4b5563;
    opacity: 0.5;
}


.p-galleria:hover .p-galleria-item-nav:hover {
    color: white;
    /* background: #183462; */
    opacity: 0.9;
}

.p-galleria .p-galleria-thumbnail-container {
    background: #4b5563;

}

div.product_proposal {
    /* padding-top: 0.5rem; */
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    width: 100%;
    background-color: white;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto
}

div.shop-proposal-list {
    max-width: 1100px;
    margin: auto
}

div.product_proposal h1 {
    text-align: center;
    color: black;
}

/* span.ql-size-small {
    font-size: 23px;
} */

.product-name-price-section {
    padding: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    /* Wyrównanie elementów do góry */
    justify-content: flex-start;
    /* Wyrównanie elementów do góry */
    align-items: flex-start;
    /* Wyrównanie do lewej */
    min-height: 180px;
    /* Minimalna wysokość */

}

.product-name-price-section h1 {
    margin: 0;
    line-height: 1;
    /* Ustawienie wysokości linii na minimalną */
    vertical-align: top;
    /* Usuń marginesy wokół nagłówka */
    padding: 0;
    /* Usuń wewnętrzne odstępy */
}

.product-name-price-section p {
    font-size: 2rem;
    margin-top: 0.5rem;
    font-style: italic;
}

.product-name-price-section p span {
    font-size: 2rem;
    margin-top: 0.5rem;
    font-style: italic;
}

.buy-section {
    flex: 1;
    /* Rozciąganie na pozostałą wolną przestrzeń */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    /* Wyrównanie do lewej */
    border-bottom: 2px solid #ddd;
    border-top: 2px solid #ddd;
}

.short-info-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: left;

}

.similar-product-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* Wyrównanie elementów do dołu */
    align-items: flex-start;
    /* Wyrównanie elementów do lewej */
    text-align: left;
    height: auto;
    /* Automatyczna wysokość */
}

h1 {
    margin-bottom: 20px;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px;
    text-decoration: none;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.facebook {
    background-color: #3b5998;
}

.instagram {
    background-color: #e1306c;
}

.allegro {
    background-color: rgb(230, 98, 10);
}

.button:hover {
    opacity: 0.8;
}