/* Główna struktura strony */
.contact-page {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
}

/* Nagłówek strony */
.contact-title {
    text-align: center;
    font-size: 28px;
    color: #4a4a4a;
    margin-bottom: 20px;
}

/* Opis kontaktu */
.contact-description {
    text-align: center;
    font-size: 16px;
    margin-bottom: 30px;
    color: #555;
}

/* Sekcja metod kontaktu */
.contact-methods {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Pojedyncza metoda kontaktu */
.contact-item {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

/* Nagłówek metody kontaktu */
.contact-heading {
    font-size: 20px;
    color: #2a7a2a;
    margin-bottom: 10px;
}

/* Linki kontaktowe */
.contact-link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.contact-link:hover {
    text-decoration: underline;
    color: #0056b3;
}