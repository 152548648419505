/* Główna kontenerka slidera */
.image-slider-container {
    position: relative;
    /* max-width: 400px; */
    /* Szerokość slidera */
    width: 100%;
    aspect-ratio: 1 / 1;
    margin: auto;
    overflow: hidden;
    /* Ukryj zdjęcia poza obszarem widocznym */
}

/* Pasek zawierający zdjęcia */
.image-slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
    padding-bottom: 100%;
    /* Zachowanie kwadratowego kształtu */
    position: relative;
}

/* Element zdjęcia */
.image-slider-item {
    min-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.image-slider-item.active {
    opacity: 1;
    position: static;
}

/* Zdjęcie */
.image-slider-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Przytnij, aby zdjęcie było kwadratowe */
}

/* Przycisk nawigacji */
.image-slider-button {
    height: 6rem;
    width: 3rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.2);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    display: flex;

    justify-content: center;
    align-items: center;
}

.image-slider-button:hover {
    background: rgba(0, 0, 0, 0.5);
}

.image-slider-button.prev {
    left: 0px;
}

.image-slider-button.next {
    right: 0px;
}

/* Kropki na dole */
.image-slider-dots {
    height: 40px;
    position: absolute;
    top: 90%;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    /* transform: translateY(-100%); */
    display: flex;
    gap: 8px;
}

.image-slider-dot {
    width: 15px;
    height: 15px;
    background: rgba(83, 81, 81, 0.7);
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s;
}

.image-slider-dot.active {
    background: rgba(255, 255, 255, 1);
}