/* Główne stylowanie kontenera */
.company-description {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
}

/* Nagłówek */
.company-title {
    text-align: center;
    font-size: 24px;
    color: #4a4a4a;
    margin-bottom: 20px;
}

/* Akapity */
.company-paragraph {
    margin-bottom: 15px;
    font-size: 16px;
    text-align: justify;
}

/* Wyróżniony akapit */
.company-highlight {
    font-size: 18px;
    font-weight: bold;
    color: #2a7a2a;
    text-align: center;
    margin-top: 20px;
}

input[type="file"] {
    margin: 10px 0;
}

.company-description img {
    display: block;
    margin: auto;
    width: 60%;
    height: auto;
    padding: auto;
}