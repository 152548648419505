.product-tile {
    position: relative;
    width: 75px;
    /* Szerokość kafelka */
    height: 75px;
    /* Wysokość kafelka */
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #ccc;
    /* Delikatny kontur */
    border-radius: 5px;
    /* Zaokrąglone rogi */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Cień */
    transition: transform 0.2s, border-color 0.2s;
    /* Animacja przy hover */
}

.product-tile:hover {
    transform: scale(1.05);
    /* Powiększanie przy najeździe */
}

.product-tile.active {
    border: 2px solid #007bff;
    /* Ramka dla aktywnego kafelka */
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Zachowuje proporcje obrazu */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    /* Ciemne tło */
    color: white;
    opacity: 0;
    /* Na początku niewidoczne */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    /* Animacja pojawiania się */
    text-align: center;
    padding: 5px;
    font-size: 0.9rem;
}

.product-tile:hover .overlay {
    opacity: 1;
    /* Pokaż overlay przy najeździe */
}

.product-name,
.product-price {
    margin: 5px 0;
    /* Odstęp między nazwą a ceną */
}