div.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  background: var(--default-background-color);
  font-family: var(-font-family);
}

header.sticky-header {
  position: sticky;
  top: 0;
  padding: 10px 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transition-timing-function: linear;
  transition-duration: 200ms;
  transition-property: all;
}

header.sticky-header img {
  width: 100px;
  height: 100px;
  transition-timing-function: linear;
  transition-duration: 200ms;
  transition-property: all;
}

header.small-header img {
  width: 50px;
  height: 50px;

}


header.small-header {
  padding-top: 0px;
  padding-bottom: 0px;
}


header {
  /* flex-basis: 5vh; */
  background-color: white;
  color: var(--text-color);

}

section.page {

  flex-grow: 1;
}

main {
  padding: 0px;
  padding-top: 0;
  flex-grow: 1;
}


.p-menubar {
  background-color: var(--surface-card);
  ;
  border: none;
  border-radius: 0;
}




.p-dataview .p-dataview-content {
  background: #f5f5dc;
}

.p-dataview-header {
  background: #f5f5dc;
  border: none
}

div.grid {
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
}