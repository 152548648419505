div.manu_slide_bar button {
    writing-mode: vertical-rl;
    text-orientation: upright;
    text-transform: uppercase;
    background-color: var(--menu-item-2-background-color);
    color: var(--menu-item-2-color);
    border-radius: 0;
    border-width: 1px;
    border-style: solid;
    border-color: var(--surface-border);

}