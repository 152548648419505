.shop-pages-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
    /* Dodaj flex-wrap, aby kafelki mogły się zawijać */
}

.page-buttons-container {
    display: flex;
    flex-wrap: wrap;
    /* Dodaj flex-wrap, aby kafelki mogły się zawijać */
    justify-content: center;
}

.page-button {

    border: none;
    padding: 10px 15px;
    margin: 5px;
    font-size: larger;
    font-weight: 600;
    /* Zmniejsz margines, aby lepiej pasowały */
    cursor: pointer;
    transition: background-color 0.3s;
    flex-shrink: 0;
    /* Zapobiega kurczeniu się przycisków */
    border-radius: 8px;
    /* Dodaj zaokrąglone rogi */
    width: 40px;
    /* Ustaw szerokość na kwadrat */
    height: 40px;
    /* Ustaw wysokość na kwadrat */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9fafb;
    color: #4b5563;
    border-radius: 6px;
    border: solid;
    border-color: #e5e7eb;
    border-width: 1px;
}

.page-button:hover {
    background-color: #caccce;
    color: #4b5563;
}

.page-button.active {
    background-color: var(--menu-item-2-background-color);
    color: var(--menu-item-2-color);
}

.dots {
    padding: 10px 15px;
    margin: 5px;
    /* Zmniejsz margines, aby lepiej pasowały */
    flex-shrink: 0;
    /* Zapobiega kurczeniu się kropek */
}