.no-access-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
}

.no-access-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #d32f2f;
}

.no-access-message {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.no-access-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #1976d2;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.no-access-button:hover {
    background-color: #1565c0;
}

.no-access-button:active {
    background-color: #0d47a1;
}