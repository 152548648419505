div.crete-product-main {
    margin: 2rem
}

div.form-field {
    display: flex;
    flex-direction: row;
    margin: 2rem
}


label.form-field {
    display: block;
    flex-direction: column;
}

.error-message {
    color: red;
}