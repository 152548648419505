div.category-box {
    width: 18rem;
    color: #6b7280;
    margin-bottom: 0.5rem;
}


div.main-category {

    background-color: #f9fafb;
    color: #4b5563;
    padding: 1.25rem;
    font-weight: 700;
    cursor: pointer;
    border-radius: 6px;
    border: solid;
    border-color: #e5e7eb;
    border-width: 1px;
}

div.main-category:hover {

    background-color: #caccce;
    color: #4b5563;

}

div.main-category-box-expanded {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

div.main-category-box-selected {
    background-color: var(--menu-item-2-background-color);
    color: var(--menu-item-2-color);
}

div.sub-category-box {
    display: block;
    /* visibility: visible; */
    /* opacity: 1; */
    /* height: 222px; */
    /* height: auto; */
    /* max-height: 10000px; */
    unicode-bidi: isolate;
    background-color: white;
    color: #4b5563;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: solid;
    border-color: #e5e7eb;
    border-width: 1px;
    border-top: none;

    transition-timing-function: linear;
    transition-duration: 1000ms;
    transition-property: display, height;

}

div.sub-category-box-hidden {
    display: none;
    /* visibility: hidden;
    opacity: 0;
    height: 0px;
    max-height: 0px;
    transition-timing-function: linear;
    transition-duration: 100ms; */
}

div.sub-category-box-selected {
    background-color: var(--menu-item-2-background-color);
    color: var(--menu-item-2-color);
}

div div.sub-category-box ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

div div.sub-category-box li {
    list-style: none;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    ;
}

div.category-box a {
    color: unset;
    text-decoration: none;
    display: block;
    margin: auto;
    padding: 0;

    /* color: unset; */

}

div.sub-category-box a {
    padding: 0.7rem;
    padding-left: 1.5rem;
}

div.sub-category-box a:active {
    color: unset;
}

div.sub-category-box a:hover {
    background-color: #caccce;
}

li.li-selected {
    font-weight: 700;
}